<script setup lang="ts">
const authCookie = useCookie('auth')
const { t } = useI18n()

const title = computed(() => t('index.header'))

useHead({ title })
</script>

<template>
  <div>
    <!-- Hero Section -->
    <section class="py-20 md:min-h-screen md:-mt-16 md:pt-36 flex items-center bg-linear-to-b from-base to-box-bg-light">
      <div class="container mx-auto px-4 text-center flex flex-col items-center">
        <h1 class="text-4xl md:text-6xl font-bold bg-gradient-to-r from-orange-500 via-red-500 to-orange-300 bg-clip-text text-transparent text-balance">
          {{ t('index.hero.title_1') }} <span class="sm:block">{{ t('index.hero.title_2') }}</span>
        </h1>

        <p class="mt-8 text-lg md:text-xl text-box-text-light text-balance">
          <span class="font-medium">Redexy</span> {{ t('index.hero.description') }}
        </p>

        <p class="mt-18">
          <NuxtLink
            :to="authCookie ? '/profile' : '/sign-up'"
            class="inline-flex items-center gap-4 text-2xl font-normal text-box-text-light bg-gradient-to-r from-red-500 to-orange-500 px-8 py-4 rounded-md"
          >
            {{ t('index.get_started') }}
            <Icon
              name="mingcute:arrow-right-line"
              class="size-8"
            />
          </NuxtLink>
        </p>
      </div>
    </section>

    <!-- Features Section -->
    <section class="py-20">
      <div class="container mx-auto px-4 text-center flex flex-col gap-8">
        <h2 class="text-3xl md:text-5xl font-bold bg-gradient-to-r from-green-300 via-sky-500 to-red-600 bg-clip-text text-transparent text-balance">
          {{ t('index.features.title') }}
        </h2>

        <p class="text-lg md:text-xl text-box-text-light text-balance">
          {{ t('index.features.description') }}
        </p>

        <div class="grid md:grid-cols-3 gap-4 md:gap-8">
          <div class="p-8 bg-linear-to-b from-box-border to-box-bg-light rounded-lg shadow-md border border-box-border flex flex-col items-center gap-2">
            <Icon
              name="mingcute:ai-line"
              class="size-10 text-orange-500"
            />
            <h3 class="text-xl font-semibold text-box-text-light text-balance">
              {{ t('index.features.ideas') }}
            </h3>
          </div>
          <div class="p-8 bg-linear-to-b from-box-border to-box-bg-light rounded-lg shadow-md border border-box-border flex flex-col items-center gap-2">
            <Icon
              name="mingcute:pic-ai-line"
              class="size-10 text-orange-500"
            />
            <h3 class="text-xl font-semibold text-box-text-light text-balance">
              {{ t('index.features.portfolio') }}
            </h3>
          </div>
          <div class="p-8 bg-linear-to-b from-box-border to-box-bg-light rounded-lg shadow-md border border-box-border flex flex-col items-center gap-2">
            <Icon
              name="mingcute:thumb-up-2-line"
              class="size-10 text-orange-500"
            />
            <h3 class="text-xl font-semibold text-box-text-light text-balance">
              {{ t('index.features.opportunities') }}
            </h3>
          </div>
        </div>
      </div>
    </section>

    <!-- For Who Section -->
    <section class="py-20 bg-box-bg">
      <div class="container mx-auto px-4">
        <h2 class="bg-gradient-to-r from-green-300 via-sky-500 to-red-600 bg-clip-text text-center text-3xl md:text-5xl font-bold text-transparent text-balance">
          {{ t('index.for_who.title') }}
        </h2>

        <div class="grid md:grid-cols-2 gap-4 md:gap-8 mt-12">
          <div class="flex flex-col gap-4 bg-linear-to-b from-box-border to-box-bg-light p-8 rounded-xl shadow-lg border border-box-border">
            <h3 class="text-box-text-light text-2xl font-semibold">
              {{ t('shared.photo_model') }}
            </h3>
            <p class="font-normal text-box-text-light">
              {{ t('index.for_who.model.description') }}
            </p>
            <ul class="flex flex-col gap-3">
              <li class="flex items-start gap-4">
                <Icon
                  name="mingcute:check-2-line"
                  class="size-5 text-orange-500 flex-shrink-0"
                />
                <p class="text-box-text-light">
                  {{ t('index.for_who.model.benefit_1') }}
                </p>
              </li>
              <li class="flex items-start gap-4">
                <Icon
                  name="mingcute:check-2-line"
                  class="size-5 text-orange-500 flex-shrink-0"
                />
                <p class="text-box-text-light">
                  {{ t('index.for_who.model.benefit_2') }}
                </p>
              </li>
              <li class="flex items-start gap-4">
                <Icon
                  name="mingcute:check-2-line"
                  class="size-5 text-orange-500 flex-shrink-0"
                />
                <p class="text-box-text-light">
                  {{ t('index.for_who.model.benefit_3') }}
                </p>
              </li>
            </ul>
          </div>

          <div class="flex flex-col gap-4 bg-linear-to-b from-box-border to-box-bg-light p-8 rounded-xl shadow-lg border border-box-border">
            <h3 class="text-box-text-light text-2xl font-semibold">
              {{ t('shared.photographer') }}
            </h3>
            <p class="font-normal text-box-text-light">
              {{ t('index.for_who.photographer.description') }}
            </p>
            <ul class="flex flex-col gap-3">
              <li class="flex items-start gap-4">
                <Icon
                  name="mingcute:check-2-line"
                  class="size-5 text-orange-500 flex-shrink-0"
                />
                <p class="text-box-text-light">
                  {{ t('index.for_who.photographer.benefit_1') }}
                </p>
              </li>
              <li class="flex items-start gap-4">
                <Icon
                  name="mingcute:check-2-line"
                  class="size-5 text-orange-500 flex-shrink-0"
                />
                <p class="text-box-text-light">
                  {{ t('index.for_who.photographer.benefit_2') }}
                </p>
              </li>
              <li class="flex items-start gap-4">
                <Icon
                  name="mingcute:check-2-line"
                  class="size-5 text-orange-500 flex-shrink-0"
                />
                <p class="text-box-text-light">
                  {{ t('index.for_who.photographer.benefit_3') }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- CTA Section -->
    <section class="py-20 bg-gradient-to-br from-box-bg-light to-box-bg text-box-text-light">
      <div class="container mx-auto px-4 text-center flex flex-col items-center gap-6">
        <h2 class="bg-gradient-to-r from-orange-500 via-red-500 to-orange-300 bg-clip-text text-3xl md:text-5xl font-bold text-transparent text-balance">
          {{ t('index.cta.title') }}
        </h2>

        <p class="text-lg md:text-xl text-balance">
          {{ t('index.cta.description_1') }}
        </p>

        <p class="text-lg md:text-xl text-balance">
          {{ t('index.cta.description_2') }}
        </p>

        <p class="py-8">
          <NuxtLink
            :to="authCookie ? '/profile' : '/sign-up'"
            class="inline-flex items-center gap-4 text-2xl font-normal text-box-text-light bg-gradient-to-r from-red-500 to-orange-500 px-8 py-4 rounded-md"
          >
            {{ t('index.get_started') }}
            <Icon
              name="mingcute:arrow-right-line"
              class="size-8"
            />
          </NuxtLink>
        </p>

        <p class="text-lg md:text-xl text-box-text-light text-balance">
          <span class="font-medium">Redexy</span> — {{ t('index.cta.description_3') }}
        </p>
      </div>
    </section>
  </div>
</template>
